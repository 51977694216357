var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "bg-cover h-full pb-10 bg-opacity-10",
      style: { backgroundImage: `url(${_vm.image})` },
    },
    [
      _c("div", { staticClass: "max-w-6xl mx-auto p-4 lg:px-8 xl:px-0" }, [
        _c(
          "div",
          {
            staticClass:
              "max-w-xl mx-auto mt-10 md:p-10 p-6 bg-white rounded-md",
          },
          [
            _c("div", { staticClass: "flex justify-between" }, [
              _c(
                "div",
                { staticClass: "flex justify-between items-center mb-3" },
                [
                  _c("div", { attrs: { id: "signin" } }, [
                    _c(
                      "button",
                      {
                        staticClass: "text-xl font-medium focus:outline-none",
                        class:
                          _vm.$route.path.search("/login") > 0
                            ? "text-green-700 border-b-2 border-green-700"
                            : "text-gray-500 hover:text-green-700 hover:border-b-2 hover:border-green-700",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("login")) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { attrs: { id: "register" } },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass:
                            "text-xl font-medium ml-6 focus:outline-none",
                          class:
                            _vm.$route.path.search("/register") > 0
                              ? "text-green-700 border-b-2 border-green-700"
                              : "text-gray-500 hover:text-green-700 hover:border-b-2 hover:border-green-700",
                          attrs: { to: _vm.localePath("/register") },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("registration")) +
                              "\n              "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("ValidationObserver", {
              ref: "observer",
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ passes, invalid }) {
                    return [
                      _c(
                        "form",
                        {
                          staticClass: "space-y-3",
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return passes(_vm.onSubmit)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "mt-2" },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  rules: "required|phoneOrEmail",
                                  name: "Username",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ valid, errors }) {
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.auth.identifier,
                                                expression: "auth.identifier",
                                              },
                                            ],
                                            staticClass:
                                              "focus:outline-none appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 sm:text-sm",
                                            class:
                                              errors.length > 0
                                                ? "border-red-400"
                                                : _vm.auth.identifier
                                                ? "border-green-600"
                                                : "border-gray-300",
                                            attrs: {
                                              name: "text",
                                              type: "text",
                                              autocomplete: "text",
                                              placeholder: _vm.$t("phone"),
                                              state: errors[0]
                                                ? false
                                                : valid
                                                ? true
                                                : null,
                                              required: "",
                                            },
                                            domProps: {
                                              value: _vm.auth.identifier,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.auth,
                                                  "identifier",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.isPhone
                            ? _c(
                                "div",
                                { staticClass: "mt-1" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "block mb-1 text-sm font-medium text-gray-700",
                                      attrs: { for: "otp" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("enter-confirm-code"))
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("ValidationProvider", {
                                    attrs: {
                                      name: "otp",
                                      rules: "required|min:4|max:4",
                                      mode: "eager",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ errors }) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "relative flex items-stretch flex-grow focus-within:z-10",
                                                },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: _vm.otp,
                                                        expression: "otp",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "focus:outline-none appearance-none block w-full px-3 py-2 border rounded-none rounded-l-md shadow-sm placeholder-gray-400 sm:text-sm",
                                                    class:
                                                      errors.length > 0
                                                        ? "border-red-400"
                                                        : _vm.otp
                                                        ? "border-green-600"
                                                        : "border-gray-300",
                                                    attrs: {
                                                      name: "otp",
                                                      type: "text",
                                                      autocomplete: "text",
                                                      placeholder:
                                                        _vm.$t(
                                                          "enter-confirm-code"
                                                        ),
                                                      required: "",
                                                    },
                                                    domProps: {
                                                      value: _vm.otp,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.otp =
                                                          $event.target.value
                                                      },
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border text-sm font-medium rounded-r-md",
                                                      class: invalid
                                                        ? "text-gray-700 bg-gray-100 hover:bg-gray-200 border-gray-600"
                                                        : "text-white focus:outline-none text-sm bg-green-700 p-3",
                                                      attrs: { type: "button" },
                                                      on: {
                                                        click: _vm.tryToLogin,
                                                      },
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("confirm")
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isPhone
                            ? _c("div", [
                                _c(
                                  "p",
                                  {
                                    staticClass: "text-sm text-gray-500 mb-2",
                                    class: [_vm.timer === "on" ? "" : "hidden"],
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t("you-can-resend-code-after")
                                        ) +
                                        "\n                "
                                    ),
                                    _vm.timer === "on"
                                      ? _c("countdown", {
                                          staticClass:
                                            "text-primary font-semibold",
                                          attrs: {
                                            "end-time": _vm.timerMinutes,
                                          },
                                          on: { finish: _vm.onCountdownEnd },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "process",
                                                fn: function (scope) {
                                                  return [
                                                    _c(
                                                      "span",
                                                      { staticClass: "ml-1" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            scope.timeObj.m
                                                          ) +
                                                            " : " +
                                                            _vm._s(
                                                              scope.timeObj.s
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isEmail
                            ? _c(
                                "div",
                                { staticClass: "mt-1" },
                                [
                                  _c("ValidationProvider", {
                                    attrs: {
                                      rules: "required|min:6",
                                      name: "password",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ valid, errors }) {
                                            return [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.auth.password,
                                                    expression: "auth.password",
                                                  },
                                                ],
                                                staticClass:
                                                  "focus:outline-none appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 sm:text-sm",
                                                class:
                                                  errors.length > 0
                                                    ? "border-red-400"
                                                    : _vm.auth.password
                                                    ? "border-green-600"
                                                    : "border-gray-300",
                                                attrs: {
                                                  name: "password",
                                                  type: "password",
                                                  placeholder:
                                                    _vm.$t("password"),
                                                  state: errors[0]
                                                    ? false
                                                    : valid
                                                    ? true
                                                    : null,
                                                  required: "",
                                                },
                                                domProps: {
                                                  value: _vm.auth.password,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      _vm.auth,
                                                      "password",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.isPhoneOtpPending
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "mt-3 w-full flex justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none",
                                  class: invalid
                                    ? "bg-gray-300"
                                    : "bg-green-600 hover:bg-green-700 text-white",
                                  attrs: { disabled: invalid, type: "submit" },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("sign-in")) +
                                      "\n              "
                                  ),
                                  _vm.loading
                                    ? _c("vue-loaders", {
                                        attrs: {
                                          name: "ball-beat",
                                          color: "white",
                                          scale: "0.5",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isPhone && _vm.times < 3
                            ? _c(
                                "div",
                                { staticClass: "flex justify-center mt-3" },
                                [
                                  _c(
                                    "p",
                                    { staticClass: "text-gray-600 text-sm" },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("did-not-get-code")) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "text-gray-400 text-sm ml-1 focus:outline-none",
                                      class: [
                                        _vm.timer !== "on"
                                          ? "text-yellow-600"
                                          : "",
                                      ],
                                      attrs: { disabled: _vm.timer === "on" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.resendCode()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("resend")) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex items-center justify-center mt-2" },
              [
                _c("p", { staticClass: "text-gray-600 text-sm font-medium" }, [
                  _vm._v(_vm._s(_vm.$t("do-not-have-an-account"))),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "ml-2 text-green-700 border-green-700 text-sm font-medium focus:outline-none",
                  },
                  [
                    _c(
                      "nuxt-link",
                      { attrs: { to: _vm.localePath("/reset-password") } },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("reset_password")) +
                            "\n            "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }